<template>
    <div>
        <div style="text-align: right">
            <el-button type="primary" size="small" @click="showEditDialog(-1, {})">新增</el-button>
        </div>
        <el-table
            :data="friendLinks"
            style="width: 100%">
            <el-table-column
                label="站点名称"
                width="280">
                <template slot-scope="scope">
                    <i class="el-icon-s-platform"></i>
                    <span style="margin-left: 10px">{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="站点链接"
                width="380">
                <template slot-scope="scope">
                    <p>{{ scope.row.url }}</p>
                </template>
            </el-table-column>
            <el-table-column
                label="排序"
                width="100">
                <template slot-scope="scope">
                    <p>{{ scope.row.sort }}</p>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        @click="showEditDialog(scope.$index, scope.row)">编辑
                    </el-button>
                    <el-button
                        size="mini"
                        type="danger"
                        @click="doDel(scope.$index)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            title="提示"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            width="35%"
        >

            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="站点名称">
                    <el-input v-model="form.title" />
                </el-form-item>
                <el-form-item label="站点连接">
                    <el-input v-model="form.url" />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="form.sort" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"  @click="doEdit(formIndex, form)">确定</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "friendLinks",
    props: {
        formData: {
            type: Object
        }
    },
    data() {
        return {
            dialogVisible: false,
            friendLinks: [
                {title: '百度', url: 'http://localhost:8080', sort: 1}
            ],
            form: {},
            formIndex: 0,
        }
    },


    mounted() {
        this.friendLinks = this.formData.config_data['friend_links'] || []
    },

    methods: {
        showEditDialog(index, row) {
            this.dialogVisible = true
            this.formIndex = index
            this.form = row
        },


        doEdit(index, row) {
            if (index === -1) {
                this.friendLinks.push(row)
            } else {
                this.friendLinks[index] = row
            }

            this.form = {}
            this.dialogVisible = false

            this.$emit('modifyFormData', {friend_links: this.friendLinks})
        },

        doDel(index) {
            this.friendLinks.splice(index, 1)
            this.$emit('modifyFormData', {friend_links: this.friendLinks})
        }
    }
}
</script>

<style scoped>

</style>
