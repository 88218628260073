<template>
    <div>
        <el-form-item prop="title" label="站点名称">
            <el-input
                size="small"
                v-model="formData.title"
                placeholder="站点名称"
            ></el-input>
        </el-form-item>

        <el-form-item prop="short_title" label="站点简称">
            <el-input
                size="small"
                v-model="formData.short_title"
                placeholder="站点简称"
            ></el-input>
        </el-form-item>

        <el-form-item prop="tel" label="联系电话">
            <el-input
                size="small"
                v-model="formData.tel"
                placeholder="联系电话"
            ></el-input>
        </el-form-item>

        <el-form-item prop="internet_code" label="备案号">
            <el-input
                size="small"
                v-model="formData.internet_code"
                placeholder="备案号"
            ></el-input>
        </el-form-item>

        <el-form-item prop="hr_email" label="人才邮箱">
            <el-input
                size="small"
                v-model="formData.hr_email"
                placeholder="人才邮箱"
            ></el-input>
        </el-form-item>

        <el-form-item prop="feedback_email" label="建议反馈邮箱">
            <el-input
                size="small"
                v-model="formData.feedback_email"
                placeholder="人才邮箱"
            ></el-input>
        </el-form-item>

        <el-form-item label="log图" prop="logo" label-width="100px">
            <UploaderImg :url.sync="formData.logo"></UploaderImg>
            <span>(100 * 40)</span>
        </el-form-item>
    </div>
</template>

<script>
import UploaderImg from "@/components/uploader/uploader-img.vue";

export default {
    name: "basicInfo",
    components: {UploaderImg},
    props: {
        formData: {
            type: Object
        }
    }
}
</script>

<style scoped>

</style>
