<template>
    <div>
        <div style="text-align: right">
            <el-button type="primary" size="small" @click="showEditDialog(-1, {})">新增</el-button>
        </div>
        <el-table
            :data="list"
            style="width: 100%">
            <el-table-column
                label="二维码"
                width="280">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="名称"
                width="380">
                <template slot-scope="scope">
                    <img :src="scope.row.url " alt="" style="width: 80px; height: 80px;">
                </template>
            </el-table-column>
            <el-table-column
                label="排序"
                width="100">
                <template slot-scope="scope">
                    <p>{{ scope.row.sort }}</p>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        @click="showEditDialog(scope.$index, scope.row)">编辑
                    </el-button>
                    <el-button
                        size="mini"
                        type="danger"
                        @click="doDel(scope.$index)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            title="提示"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            width="35%"
        >

            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="二维码" prop="url" label-width="100px">
                    <UploaderImg :url.sync="form.url"></UploaderImg>
                    <span>(100 * 100)</span>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="form.title" />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="form.sort" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"  @click="doEdit(formIndex, form)">确定</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>

import UploaderImg from "@/components/uploader/uploader-img.vue";

export default {
    name: "qrcodeConfig",
    components: {UploaderImg},
    props: {
        formData: {
            type: Object
        },
        qrcodeType: {
            type: String,
        }
    },

    mounted() {
        this.list = this.formData.config_data[this.qrcodeType] || []
    },

    data() {
        return {
            dialogVisible: false,
            list: [
                {title: '百度', url: 'http://localhost:8080', sort: 1}
            ],
            form: {},
            formIndex: 0,
        }
    },

    methods: {
        showEditDialog(index, row) {
            this.dialogVisible = true
            this.formIndex = index
            this.form = row
        },


        doEdit(index, row) {
            if (index === -1) {
                this.list.push(row)
            } else {
                this.list[index] = row
            }

            this.form = {}
            this.dialogVisible = false

            let config = {}
            config[this.qrcodeType] = this.list
            this.$emit('modifyFormData', config)
        },

        doDel(index) {
            this.list.splice(index, 1)

            let config = {}
            config[this.qrcodeType] = this.list

            this.$emit('modifyFormData', config)
        }
    }
}
</script>

<style scoped>

</style>
