<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-form ref="form" :model="formData"  label-width="100px">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="基本信息" name="basicInfo">
                    <BaseInfo :form-data="formData"></BaseInfo>
                </el-tab-pane>
                <el-tab-pane label="公众号" name="official">
                    <qrcode-config
                        v-if="isLoad"
                        :form-data="formData"
                        @modifyFormData="modifyFormData"
                        qrcodeType="official"
                    />
                </el-tab-pane>
                <el-tab-pane label="小红书" name="redBook">
                    <qrcode-config v-if="isLoad" :form-data="formData" @modifyFormData="modifyFormData" qrcodeType="red_book" />
                </el-tab-pane>
                <el-tab-pane label="微博" name="weibo">
                    <qrcode-config v-if="isLoad" :form-data="formData" @modifyFormData="modifyFormData" qrcodeType="weibo" />
                </el-tab-pane>
                <el-tab-pane label="友情链接" name="friendLinks">
                    <FriendLinks v-if="isLoad" :form-data="formData" @modifyFormData="modifyFormData"></FriendLinks>
                </el-tab-pane>
            </el-tabs>
            <el-form-item label=" " label-width="100px">
                <el-button size="small" type="primary" @click="save">保存</el-button>
                <el-button size="small" type="" @click="getDetail()">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import * as mammoth from 'mammoth'
import {getInfo, addAndEdit} from './api'
import BaseInfo from "./components/basicInfo.vue";
import FriendLinks from "@/views/operation/web-site/components/friendLinks.vue";
import QrcodeConfig from "@/views/operation/web-site/components/qrcodeConfig.vue";

export default {
    name: 'List',
    components: {QrcodeConfig, FriendLinks, BaseInfo},
    data() {
        return {
            isLoad: false,
            activeName: 'basicInfo',
            formData: {
                id: 0,
                title: '',
                short_title: '',
                logo: '',
                tel: '',
                hr_email: '',
                feedback_email: '',
                zx_qr_code: '',
                internet_code: '',
                config_data: {
                    official: [],
                    red_book: [],
                    weibo: [],
                    friend_links: [],
                }
            }
        }
    },

    async created() {
        await this.getDetail()
        this.isLoad = true
    },
    methods: {
        modifyFormData(val) {
            console.log('val',  Object.assign(this.formData.config_data, val))
            this.formData.config_data = Object.assign(this.formData.config_data, val)
            console.log(this.formData)
        },

        async getDetail() {
            this.formData = await getInfo()
        },
        getUploadUrl(imageUrl) {
            this.formData.bg_image = imageUrl
        },
        save() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    console.log(this.formData, 'dddd@@@@@')
                    await addAndEdit(this.formData).then(() => {
                        this.$message.success('编辑成功')
                        this.getDetail()
                    })
                } else {
                    return false
                }
            })
        },
        cancel() {
            // 初始化编辑器
            //this.$refs.editor.init()
        },
        handlerArtSuccess(file) {
            console.log(file)
            let reader = new FileReader()
            reader.readAsArrayBuffer(file.raw)
            reader.onload = (e) => {
                const data = e.target.result
                mammoth.convertToHtml({arrayBuffer: data}).then((res) => {
                    this.displayResult(res.value)
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
