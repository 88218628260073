import {
    http
} from '@/http/axios.js'


export function addAndEdit(data) {
    return http({
        url: `/company/basic/website/addAndEdit`,
        method: 'post',
        data
    })
}

export function getInfo() {
    return http({
        url: `/company/basic/website/detail`,
        method: 'get',
    })
}
